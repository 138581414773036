import axios from "axios";
import { BASE_URL } from "../configs";
import { axiosInstanceVisit } from "./config";

const URL = BASE_URL + "/visiteur";

export const getAllVisiteurs = () => {
  return axios.get(`${URL}`);
};

export const registerVisiteur = (data) => {
  const newPerson = {
    first_name_visit: data.first_name_visit,
    last_name_visit: data.last_name_visit,
    email_visit: data.email_visit,
    pwd_visit: data.pwd_visit,
  };
  return axios.post(`${URL}`, newPerson);
};

export const loginVisiteur = (data) => {
  return axios.post(`${BASE_URL}` + "/login-visiteur", data);
};

/* axios.defaults.headers["Authorization"] =
  "Bearer " + localStorage.getItem("token"); */

export const isLoggedIn = () => {
  return axios.get(`${BASE_URL}` + "/verify", {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

export const requestForgetPassword = (data) => {
  return axios.post(`${BASE_URL}` + "/forget-password", data);
};

export const resetPassword = (data) => {
  return axios.post(`${BASE_URL}` + "/reset-password", data);
};

export const updateInfoVisiteur = (id, data) => {
  return axiosInstanceVisit.put(`${BASE_URL}/me-visiteur/${id}`, data);
};

export const updateMdpVisiteur = (id, data) => {
  return axiosInstanceVisit.put(`${BASE_URL}/login-visiteur/${id}`, data);
};
