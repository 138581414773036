<template>
  <LayoutVisiteur>
    <section class="container-fluid bg-light">
      <div class="row p-2 p-lg-5">
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
        <div class="col-12 col-lg-4 bg-secondary shadow text-light rounded p-4">
          <form
            class="row g-3"
            @submit.prevent="confirm"
            formnovalidate="formnovalidate"
          >
            <h2 class="text-primary">Retrouvez votre compte</h2>
            <hr />
            <div>
              Veuillez entrer votre adresse e-mail pour reinisitialiser votre
              mot de passe.
            </div>
            <div class="col-12">
              <label for="inputMail1" class="form-label">Mail</label>
              <input
                type="email"
                class="form-control"
                id="inputMail1"
                v-model="form.email_visit"
                :class="{
                  'is-invalid': submitted && $v.form.email_visit.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.email_visit.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.email_visit.required"
                  >Veuillez insérer votre adresse mail</span
                >
                <span v-if="!$v.form.email_visit.email"
                  >Veuillez insérer une adresse mail valide</span
                >
              </div>
            </div>
            <div class="col-12 mb-2 mt-4 text-end">
              <button
                v-if="loading"
                class="btn btn-primary"
                type="button"
                disabled
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Envoi...
              </button>
              <button
                v-else
                type="submit"
                class="btn btn-primary"
                formnovalidate="formnovalidate"
              >
                Envoyer
                <!-- <IcoSend /> -->
              </button>
            </div>
          </form>
        </div>
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import { success, error } from "../../utils/toast";
import { requestForgetPassword } from "../../api/visiteur";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "MdpForget",
  components: {
    LayoutVisiteur,
  },
  data() {
    return {
      form: {
        email_visit: "",
      },
      loading: false,
      submitted: false,
    };
  },
  validations: {
    form: {
      email_visit: { required, email },
    },
  },
  methods: {
    confirm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      requestForgetPassword(this.form)
        .then((result) => {
          if (result.data.error) {
            //console.log(result.data.error);
            error(result.data.error);
            this.loading = false;
          } else {
            this.loading = false;
            success("Vérifier votre courriel");
            //console.log(result);
          }
        })
        .catch((/*err*/) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
