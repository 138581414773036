import { render, staticRenderFns } from "./MdpForget.vue?vue&type=template&id=de02eec2&scoped=true&"
import script from "./MdpForget.vue?vue&type=script&lang=js&"
export * from "./MdpForget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de02eec2",
  null
  
)

export default component.exports